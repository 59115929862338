import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment.prod';
import { AppConstants } from 'src/app/app.constants';
import { MiddlearticlesInterface } from 'src/app/interfaces/performance-section.interface';
import { WebinfoService } from 'src/app/service/webinfo.service';
import { SubscribePopupComponent } from './subscribe-popup/subscribe-popup.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgStyle, NgIf } from '@angular/common';
import { BackgroundImageDirective } from 'src/app/directive/background-image.directive';

@Component({
    selector: 'app-subscribe',
    templateUrl: './subscribe.component.html',
    styleUrls: ['./subscribe.component.css'],
    standalone: true,
    imports: [
        NgStyle,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        NgIf,
        BackgroundImageDirective
    ],
})
export class SubscribeComponent implements OnInit {
  firstObj: number = this.appConstants.firstObj;
  domainImagePath: string = environment.domainImageBasePath;
  cdnImagePath = environment.cdnImagePath;
  emaildata: MiddlearticlesInterface[] = [];
  loading: boolean = false;
  successMessage: string = '';
  errorMessage: string = '';
  backgroundImage: string;
  extractedContent: { secondDiv: string; firstP: string } = { secondDiv: '', firstP: '' };
  emailForm: FormGroup;

  constructor(
    private appConstants: AppConstants,
    private webinfoHttp: WebinfoService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {
    this.webinfoHttp.infoList().subscribe(data => {
      this.emaildata = data[this.firstObj].data;
      const backImage = this.emaildata.find(item => item.title === 'Join Our Email List');
      this.backgroundImage = backImage.secondary_image;

      // Extract content from short_description
      const shortDescription = backImage?.short_description || '';
      this.extractedContent = this.extractContentFromHTML(shortDescription);

    });
  }

  onSubmit(): void {
    if (this.emailForm.valid) {
      const email = this.emailForm.get('email')?.value;
      this.webinfoHttp.subscribe(email).subscribe({
        next: (response) => {
          if (response[0]?.success == true) {
            this.openSubscribePopUpSuccess();
            setTimeout(() => {
              this.emailForm.reset();
              this.errorMessage = '';
            }, 2000);
          } else {
            this.errorMessage = 'Please enter a valid email address.';
          }
        },
      });
    } else {
      this.markFormGroupTouched(this.emailForm);
      this.errorMessage = 'Please enter a valid email address.';
    }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  openSubscribePopUpSuccess(): void {
    const dialogRef = this.dialog.open(SubscribePopupComponent, {
      width: '250px',
      data: { message: 'You have successfully signed up to get the best deals.' },
    });
    dialogRef.afterClosed().subscribe(() => { });
  }

  extractContentFromHTML(html: string): { secondDiv: string; firstP: string } {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const secondDiv = doc.querySelectorAll('div')[1]?.outerHTML || '';
  const firstP = doc.querySelectorAll('p')[0]?.outerHTML || '';
  return { secondDiv, firstP };
}

}
