<div class="container-fluid" *ngIf="relatedproduct?.length > 0">
    <div class="rec_viewed_items">
        <div class="row">
            <div class="col-md-12">
                <ul class="nav nav-tabs text-center" role="tablist">
                    <li class="active" style="width: 100%;">
                        <a href="#rv_tab1" role="tab" data-toggle="tab" class="btn-full" style="display: inline-block">RELATED PRODUCTS</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane fade tab-cont-recently active in" id="rv_tab1">
                        <section class="section light-gray-bg clearfix p-0 sf_slider_section hand-pointer">
                            <ng-image-slider #nav [images]="imageObject" [imagePopup]="false" [infinite]="false" [autoSlide]="1" [imageSize]="{width: '382px', height: '234px'} " slideImage="1" (imageClick)="productRedirection($event)">
                            </ng-image-slider>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
