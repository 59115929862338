// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`


//add and set domain imports here
function environmentPromise(): Promise<any>{
  const domainName = window.location.hostname;
  return new Promise<any>((resolve,reject) =>{
    let domainConfig: any;
    (async ()=>{
      if (domainName.includes('localhost')) {
        domainConfig = await import('./moparproweb.json');
      }
      else if(domainName.includes('mcleod')){
        domainConfig = await import('./mcleodracingweb.json');
      }
      else if(domainName.includes('moparpro')){
        domainConfig = await import('./moparproweb.json');
      }
      else if(domainName.includes('onepieceproducts')){
        domainConfig = await import('./onepieceproductsweb.json');
      }
	    else if(domainName.includes('shiftsst')){
        domainConfig = await import('./shiftsstweb.json');
      }
      else if(domainName.includes('prwpower')){
        domainConfig = await import('./prwpowerweb.json');
      }
      else{
        domainConfig = await import('./env.json');
      }

      setDomainConfig(domainConfig);

      resolve(environment);
    })();
  })
}


function setDomainConfig(domainConfig){
  environment.apiUrl = domainConfig.apiUrl;
  environment.cdnImageBasePath = domainConfig.cdnImageBasePath;
  environment.domainImageBasePath = domainConfig.domainImageBasePath;
  environment.storeImagePath = domainConfig.storeImagePath;
  environment.cdnImagePath = domainConfig.cdnImagePath;
  environment.websitecdn = domainConfig.websitecdn;
  environment.client_id = domainConfig.client_id;
}


export const environment = {
  production: false,
  apiUrl: '',
 // endpoint: 'http://127.0.0.1:8000/api/v1/',
//  endpoint2: 'http://127.0.0.1:8000/',

  //endpoint: 'https://mcleodracing.sebdemo.com/api/v1/',
  //endpoint2: 'https://mcleodracing.sebdemo.com/',

   //image base path
  cdnImageBasePath: '',
  domainImageBasePath: '',
  storeImagePath: '',
  cdnImagePath: '',
  websitecdn: '',
  //Social Media keys
  client_id: '',

  errorMessage:
    'Unable to process your request at this moment. Please try again after some time. If issue still presist, then please contact to our 24x7 support team.',
  };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

export const environmentAsync = environmentPromise();
