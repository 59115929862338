import { ResolveFn } from '@angular/router';
import { of } from 'rxjs';
import { ProductDetailsComponent } from '../templates/featured-product/component/product-details/product-details.component';
import { CategoryDetailsComponent } from '../templates/featured-category/component/category-details/category-details.component';
import { ErrorPageComponent } from '../templates/error-page/error-page.component';
import { CategoryDabComponent } from '../templates/featured-category/component/category-details/category-dab/category-dab.component';


export const placeholderComponentResolver : ResolveFn<any> = (route, state)=>{
    //change the following after getting the setting from api
    const isDapTemplate: boolean = false;
    const id: string = route.params['id'];
    const pattern: boolean = /^[0-9]*$/.test(id.slice(1,id.length));
    if (id.length >1 && id.startsWith('p') && pattern) {
      let product_key = id.slice(1,id.length);
      localStorage.setItem('selectedproductkey',String(product_key));
      localStorage.removeItem('selectedcategorykey');
      return of(ProductDetailsComponent)
    }
    else if(id.length >1 && id.startsWith('c') && pattern){
      let category_key = id.slice(1, id.length);
      localStorage.setItem('selectedcategorykey',String(category_key));
      localStorage.removeItem('selectedproductkey');
      if(isDapTemplate) return of(CategoryDabComponent);
      return of(CategoryDetailsComponent)
    }
    else{
      return of(ErrorPageComponent)
    }
  }
