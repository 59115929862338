import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable, Subject } from 'rxjs';
import { ProductService } from './product.service';


@Injectable({
  providedIn: 'root',
})
export class CommunicationService {

  onClickUseAddress: EventEmitter<boolean> = new EventEmitter<boolean>();

  onClickShipping: EventEmitter<boolean> = new EventEmitter<boolean>();

  onClickPayment: EventEmitter<boolean> = new EventEmitter<boolean>();

  searchByPartNameProductKeyChange: Subject<null> = new Subject<null>();

  serchByPartHashKeyChange: Subject<null> = new Subject<null>();

  categoryKeyChange: Subject<null> = new Subject<null>();

  itemCount: Subject<null> = new Subject<null>();

  dealerChangeEffectSub: Subject<boolean> = new Subject<boolean>();

  addToCartService$ = new Subject<any>();

  private addItemToCartSubject = new BehaviorSubject<string[]>([]);
  serviceAddItemToCart$ = this.addItemToCartSubject.asObservable();

  //cart login popup
  closedDialog$ = new Subject<any>();

  // customer-login is_corporate user dropdown in header component
  private isCorporateUser = new BehaviorSubject<boolean>(localStorage.getItem('isCorporateUser') === 'true');
  isCorporateUser$ = this.isCorporateUser.asObservable();

  private dropdownData = new BehaviorSubject<any[]>(JSON.parse(localStorage.getItem('dropdownData') || '[]'));
  dropdownData$ = this.dropdownData.asObservable();

  private dealerLocationSource = new BehaviorSubject<any>(null);
  dealerLocation$ = this.dealerLocationSource.asObservable();

  //cart-application communication
  orderIDGenerateEmitter: EventEmitter<null> = new EventEmitter<null>();

  //Product-Details get username
  usernameSubject : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  usernameObservable : Observable<boolean> = this.usernameSubject.asObservable();

  //search-by-vehicle - vehicle search - header nav vehicle name

  vehiclenameSubject : Subject<string> = new Subject<string>();

  // cart-promtion response send to the cart page
  private cartDataSubject = new BehaviorSubject<any>(null);
  cartData$ = this.cartDataSubject.asObservable();

  // save for later
  private saveForLaterSource = new Subject<any>();
  saveForLater$ = this.saveForLaterSource.asObservable();

  //recently view response send to the product details page
   private loaderSubject = new BehaviorSubject<boolean>(false);
  loaderState$ = this.loaderSubject.asObservable();

  private verifyCartOnAddress = new Subject<any>();
  verifyCheckout$ = this.verifyCartOnAddress.asObservable();

  private doNotChangeTotalValue = new Subject<any>();
  doNotChangeTotalValue$ = this.doNotChangeTotalValue.asObservable();

  private paymentMethodSetting = new BehaviorSubject<any>(null);
  paymentMethodSetting$ = this.paymentMethodSetting.asObservable();

  // product-may-you-like products added in list when they removed from cart component

  private productsSubject = new BehaviorSubject<any[]>([]);
  products$ = this.productsSubject.asObservable();

  private productAddedSource = new BehaviorSubject<any>(null);
  productAdded$ = this.productAddedSource.asObservable();

  private productRemovedSource = new BehaviorSubject<any>(null);
  productRemoved$ = this.productRemovedSource.asObservable();

  // Cart-Promotion products added in list when they removed from cart component
  private productSubject = new BehaviorSubject<any[]>([]);
  product$ = this.productSubject.asObservable();

  private productAddSource = new BehaviorSubject<any>(null);
  productAdd$ = this.productAddSource.asObservable();

  private productRemoveSource = new BehaviorSubject<any>(null);
  productRemove$ = this.productRemoveSource.asObservable();

  constructor(
    private productService: ProductService
  ) {}


  serviceAddItemToCart(selectedProduct: string[]) {
    this.addItemToCartSubject.next(selectedProduct);
  }


 setIsCorporateUser(value: boolean): void {
    localStorage.setItem('isCorporateUser', value.toString());
    this.isCorporateUser.next(value);
  }

  setDropdownData(data: any[]): void {
    localStorage.setItem('dropdownData', JSON.stringify(data));
    this.dropdownData.next(data);
  }


  sendDealerLocation(location: any) {
    this.dealerLocationSource.next(location);
  }




  setCartData(data: any) {
    this.cartDataSubject.next(data);
  }


  notifySaveForLater(item: any) {
    this.saveForLaterSource.next(item);
  }


  showLoader() {
    this.loaderSubject.next(true);
  }

  hideLoader() {
    this.loaderSubject.next(false);
  }


  notifyVerifyCart(item: any) {
    this.verifyCartOnAddress.next(item);
  }


  doNotChangeTotal(item: any) {
    this.doNotChangeTotalValue.next(item);
  }


  paymentMethodSettings(item: any) {
    this.paymentMethodSetting.next(item);
  }


  updateProducts(products: any[]) {
  this.productsSubject.next(products);
  }

  addProductToCart(productVariationKey: number) {
    this.productAddedSource.next(productVariationKey);
  }

  removeUpshellsProductFromCart(productVariationKey: number) {
    this.productRemovedSource.next(productVariationKey);
  }




  updateProducted(products: any[]) {
  this.productSubject.next(products);
  }

  addedProductInCart(productVariationKey: number) {
    this.productAddSource.next(productVariationKey);
  }

  removeCartPromotionProductFromCart(productVariationKey: number) {
    this.productRemoveSource.next(productVariationKey);
  }

  dealerChangeEffect(){
    this.dealerChangeEffectSub.next(true);
  }

  async dealerChange(){
    this.dealerChangeEffectSub.subscribe(
      {
        next: async(value) =>
        {
          if (value) {
            this.productService.initializeAllProductDetailsInfoOfApplication();
            this.productService.initializeMasterProductsDetails();
          }
        },
      }
    )
  }

  private removeItemsSubject = new Subject<void>();
  removeItems$ = this.removeItemsSubject.asObservable();

  triggerRemoveItems() {
    this.removeItemsSubject.next();
  }


}
