<div class="wheel_cat_listing_background wicked-cate-wra blue_background 1">
    <div class="container">
        <div class="row">
            <div *ngIf="isVehicleSearch" class="col-md-12">
                <h1 style="text-align: center; margin-top: 0;font-size: 30px;color: #505050; text-transform: unset">
                    {{vehicleName}}
                </h1>
                <hr style="background: rgba(0, 0, 0, 0) linear-gradient(to right, #ddd 0%, #e1e1e1 35%, rgba(0, 0, 0, 0.05) 70%, rgba(0, 0, 0, 0) 100%) repeat scroll 0 0;border: none;height: 1px;">
            </div>
            <!--  -->
            <div>
                <div class="isotope-container grid-space-10 text-center" style="position: relative; ">
                    <div *ngFor="let subcategories of subCategories" class="col-md-3 col-sm-6 isotope-item " style="position: absolute; left: 0px; top: 0px; ">
                        <div class="imgbox_outer img-rel promo-tag-2 ">
                            <div class="owl-carousel content-slider-with-large-controls owl-theme " style="opacity: 1; display: block; ">
                                <div class="owl-wrapper-outer ">
                                    <div class="owl-wrapper " style="width: 568px; left: 0px; display: block; ">
                                        <div class="owl-item " style="width: 284px; ">
                                            <div class="image-box text-center ">
                                                <div class="overlay-container overlay-container_cat_details " style="cursor: pointer; ">
                                                    <div class="overlay-container_outer ">
                                                        <div class="overlay-container_inner ">
                                                            <div class="teaser disp-in lazyloaded " data-expand="-40 ">
                                                                <img [src]="subcategories?.icon_image ? cdnImagePath+subcategories?.icon_image : cdnImagePath+ subcategories.category_image " [alt]="subcategories?.name " [title]="subcategories?.name" class=" lazyloaded ">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="overlay-top ">
                                                        <div class="text ">
                                                            <h3>
                                                                <a [title]="subcategories?.name " (click)="categoryRedirection(+subcategories.category_key,subcategories?.name) ">
                                                                    {{subcategories?.name}}
                                                                </a>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div class="overlay-bottom ">
                                                        <div class="links ">
                                                            <a (click)="categoryRedirection(+subcategories?.category_key, subcategories?.name) " class="btn btn-gray-transparent btn-sm btn_viewcategory ">
                                                                <i class="fa fa-search "></i>
                                                            </a>
                                                            <a (click)="categoryRedirection(+subcategories?.category_key, subcategories?.name) " class="cat_golink ">
                                GO
                              </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="owl-controls clickable " style="display: none; ">
                                    <div class="owl-pagination ">
                                        <div class="owl-page active ">
                                            <span class=" "></span>
                                        </div>
                                    </div>
                                    <div class="owl-buttons ">
                                        <div class="owl-prev ">
                                            prev
                                        </div>
                                        <div class="owl-next ">
                                            next
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="prod-title category-title ">
                            <h2>
                                <a class="f-16 " [title]="subcategories?.name " (click)="categoryRedirection(+subcategories?.category_key, subcategories?.name) ">
                                    <span>{{subcategories?.name}}</span>
                                </a>
                            </h2>
                        </div>
                    </div>
                    <div #loadMoreRef class="load-more-trigger"></div>
                    <!--<div *ngIf="(subCategories?.length > limitSubCategories) && (!hideCategoriesLoadMore)" class="col-md-12 text-center" style="overflow: hidden;clear: both;">
                        <a (click)="onClickCategoryLoadMore()" class="btn btn-default btn-sm margin-clear btn_more" style="font-size: 15px;">
                            Load More
                        </a>
                    </div>-->
                </div>
                <!-- product div  -->
                <div *ngIf="allProducts.length > 0" id="ProductDiv">
                    <div *ngIf="subCategories.length > 0">
                        <h1 style="text-align: center;margin-top: 0;font-size: 24px;text-transform: unset">
                            Products
                        </h1>
                        <hr style="background: rgba(0, 0, 0, 0) linear-gradient(to right, #ddd 0%, #e1e1e1 35%, rgba(0, 0, 0, 0.05) 70%, rgba(0, 0, 0, 0) 100%) repeat scroll 0 0;border: none;height: 1px;">
                    </div>

                    <div class="isotope-container grid-space-10 text-center" style="position: relative;">
                        <div *ngFor="let product of allProducts" class="col-md-3 col-sm-6 isotope-item" style="position: absolute; left: 0px; top: 0px;">
                            <div class="imgbox_outer img-rel promo-tag-2">
                                <span *ngIf="catProductPromotion[0]" class="promo-tag" style="margin-bottom: 10px; display: inline-block;">
                  <img src="../../../../../assets/images/transparent_pixel.gif" class="promo_img lazyloaded">
                  <a style="cursor:pointer; text-decoration:none;">
                    <span data-toggle="modal" data-target="#myModal-promotion-it8">
                      <span class="promo-tag-temp" style="padding-right: 5px;">
                        {{catProductPromotion[0]?.short_description}}&nbsp;
                      </span>
                                <span class="sf_b_tag">
                        <span class="promo-per">
                          <span
                          *ngIf="catProductPromotion[0]?.promotion_type == 'discount_flat_rate'">
                            $
                          </span> {{catProductPromotion[0]?.discount}}
                                <span *ngIf="catProductPromotion[0]?.promotion_type == 'percentage_discount'">
                            %
                          </span> &nbsp;OFF
                                </span>
                                </span>
                                </span>
                                </a>
                                </span>
                                <div class="owl-carousel content-slider-with-large-controls owl-theme" style="opacity: 1; display: block;">
                                    <div class="owl-wrapper-outer">
                                        <div class="owl-wrapper" style="width: 100%; left: 0px; display: inline-block;">
                                            <div class="owl-item">
                                                <div class="image-box  text-center">
                                                    <div class="overlay-container overlay-container_cat_details" style="cursor: pointer;">
                                                        <!-- onClick="window.open('/p','_self');" -->
                                                        <div class="overlay-container_outer">
                                                            <div class="overlay-container_inner">
                                                                <div class="teaser disp-in lazyloaded" data-expand="-40">
                                                                    <img [src]="
                                    !!product?.products?.[firstObj]?.product_photo?.large_image && product?.products?.[firstObj]?.product_photo?.large_image.length > 0 ?
                                    cdnImagePath+product?.products?.[firstObj]?.product_photo?.large_image : '../../../../../../assets/images/category_landing_sf.png'" [alt]="product?.products?.[firstObj]?.name" [title]="product?.products?.[firstObj]?.name"
                                                                        class=" lazyloaded">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="overlay-top" (click)="productRedirection(product?.products?.[firstObj])">
                                                            <div class="text">
                                                                <h3>
                                                                    <a [title]="product?.products?.[firstObj]?.name">
                                    {{product?.products?.[firstObj]?.name}}
                                  </a>
                                                                </h3>
                                                            </div>
                                                        </div>

                                                        <div class="overlay-bottom" (click)="productRedirection(product?.products?.[firstObj])">
                                                            <div class="links">
                                                                <a (click)="productRedirection(product?.products?.[firstObj])" class="btn btn-gray-transparent btn-sm btn_viewcategory">
                                                                    <i class="fa fa-search"></i>
                                                                </a>
                                                                <a (click)="productRedirection(product?.products?.[firstObj])" class="cat_golink">
                                  GO
                                </a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="owl-controls clickable" style="display: none;">
                                        <div class="owl-pagination">
                                            <div class="owl-page active"><span class=""></span></div>
                                        </div>
                                        <div class="owl-buttons">
                                            <div class="owl-prev">prev</div>
                                            <div class="owl-next">next</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="prod-title category-title">
                                <h2>
                                    <a class="f-16" [title]="product?.name" (click)="productRedirection(product?.products?.[firstObj])">
                                        <span>
                      {{product?.products?.[firstObj]?.name}}
                    </span>
                                    </a>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <nav class="text-center best_seller_pagination">
                            </nav>
                        </div>
                    </div>
                </div>
                <div *ngIf="isVehicleSearch " class="col-md-12 text-center " style="overflow: hidden;clear: both; ">
                    <a (click)="onClearVehicleSearch() ">
                        <button type="button " class="btn btn-group btn-default " id="enableOnInput ">
              CLEAR VEHICLE
            </button>
                    </a>
                </div>
                <div #loadMoreRef class="load-more-trigger"></div>
                <!--<div *ngIf="(subProducts?.length > limitSubProducts) && (!hideProductsLoadMore)" class="col-md-12 text-center" style="overflow: hidden;clear: both;">
                    <a (click)="onClickProductLoadMore()" class="btn btn-default btn-sm margin-clear btn_more" style="font-size: 15px;">
                        Load More
                    </a>
                </div>-->
                <br>
                <app-category-breadcrumb [name]="name" [category_key]="category_key" />

            </div>
        </div>
    </div>
</div>