<!-- [ngStyle]="{'background-image': 'url(' + cdnImagePath + (emaildata?.[7]?.secondary_image || '') + ')'}" -->
<section class="default-bg subscribe-bg" [appLazyLoadBackground]="cdnImagePath+backgroundImage">
  <div class="container-fluid gray-light-translucent-bg">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="call-to-action text-center">
          <div class="row">
            <div class="col-md-12">
              <div [innerHTML]="extractedContent.secondDiv"></div>
              <div [innerHTML]="extractedContent.firstP"></div>
              <div class="d-none d-sm-block">
                <form class="form-inline frmsubscription" [formGroup]="emailForm" (ngSubmit)="onSubmit()">
                  <div class="form-group has-feedback">
                    <label class="sr-only" for="email_address_id">Email address</label>
                    <input class="form-control form-control-lg" id="email_address_id" placeholder="Enter email"
                      type="email" formControlName="email">
                    <i class="fa fa-envelope form-control-feedback"></i>
                    <button class="btn btn-lg f-bold-o margin-clear" id="get_coupons_button"
                      style="vertical-align: top;" type="submit">SUBMIT</button>
                  </div>
                  <p id="coupon_subscription_msg_upd" *ngIf="errorMessage">{{errorMessage}}</p>
                </form>
              </div>
              <div class="d-block d-sm-none">
                <form class="form-inline frmsubscription" [formGroup]="emailForm" (ngSubmit)="onSubmit()">
                  <div class="input-group">
                    <label class="sr-only" for="email_address_id">Email address</label>
                    <input class="form-control form-control-xs" id="email_address_id" placeholder="Enter email"
                      type="email" formControlName="email">
                    <span class="input-group-btn"><button class="btn btn-secondary" id="get_coupons_button"
                        type="submit"><i class="fa fa-paper-plane"></i></button></span>
                  </div>
                  <p id="coupon_subscription_msg_upd" *ngIf="errorMessage">{{errorMessage}}</p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
