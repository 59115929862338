<div class="main-navigation animated with-dropdown-buttons d-sm-none d-md-none d-lg-none">
    <nav class="navbar navbar-default margin-clear" role="navigation">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse-2" (click)="toggleNavbar()" [attr.aria-expanded]="!isNavbarCollapsed">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="rocket_logo_position" [routerLink]="['/']">
                <img id="logo_img" [src]="storeImagePath+headerdata?.websiteHeader[firstObj+2]?.desktop_logo" alt="mcleodracing" />
            </a>
            <div class="searchBoxMobileView dropdown pull-right displayDropDownMenuSearch">
                <button (click)="onClickSearchIcon()" type="button" class="btn btnsearch dropdown-toggle" data-toggle="dropdown" #SearchIcon>
                    <i class="fa fa-search icon-search"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-right dropdown-animation" [ngStyle]="{'display': displayDropDownMenuSearch? 'block' : 'none'}" #DropdownMenuSearch>
                    <li>
                        <div class="panel-body text-right compare-dropdown">
                            <a (click)="boolSearchDivName()" class="btn btn-group btn-gray btn-sm f-14" style="margin-bottom: 12px" id="dropdownMenuAnchorMob1" #ListsearchByPartName>
                                Search By Part Name
                            </a>
                            <a class="btn btn-group btn-gray btn-sm f-14" id="dropdownMenuAnchorMob2" (click)="boolSearchDivHash()" #ListsearchByPartHash>
                                Search By Part #
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="collapse navbar-collapse" id="navbar-collapse-2" [ngClass]="{'collapse': isNavbarCollapsed}">
            <ul class="nav navbar-nav header_mobile_menu">
                <li *ngFor="let header of headerdata?.thirdRow; let i = index" class="dropdown mega-menu tabMenus">
                    <a class="dropdown-toggle leftbgimagenone" (click)="toggleDropdown(i)" [innerHTML]="header?.menu_name == 'Search By Vehicle ' ? vehicleImage() : displayName(header?.menu_name)" (mouseover)="applyCustomVehicleClassMethod(header?.menu_name)" (mouseleave)="removeCustomVehicleClassMethod(header?.menu_name)"
                        [ngClass]="{'customHoverClassVehicle': header?.menu_name=='Search By Vehicle ' ? applyCustomVehicleClass : false, 'customHoverClass': header?.menu_name !='Search By Vehicle ' ? applyCustomClass : false}" #navBarElements>
                    </a>

                    <ul *ngIf="menuData[header.category_key]?.length > 0" class="dropdown-menu subcategory_menu_block subcategory_menu_list" [ngClass]="{'active': activeDropdownIndex === i}" (mouseover)="applySubCategoryCustomClass(header?.menu_name)" [id]="header?.menu_name"
                        #dropdownItemsNavBar>
                        <li class="dropdown mega-menu" *ngFor="let menu of menuData[header.category_key]">
                            <a class="dropdown-toggle wrap_text" [title]="menu?.name" (click)="subCategoryRedirection($event,dropdownItemsNavBar,menu.category_key, 'mobile')" [routerLink]="['/'+menu.name.replaceAll(' ','-').toLowerCase(),'c'+menu.category_key]">
                                <span class="cate_name">{{menu?.name}}</span>
                            </a>
                            <ul *ngIf="menuData[menu.category_key]?.length > 0" class="dropdown-menu subcategory_menu_block subcategory_menu_list subCat" [ngClass]="{'active': activeDropdownIndex === i}" (mouseover)="applySubCategoryCustomClass(header?.menu_name)">
                                <li *ngFor="let item of menuData[menu.category_key]" class="dropdown mega-menu">
                                    <a class="dropdown-toggle wrap_text" [title]="item?.name" (click)="subCategoryRedirection($event,dropdownItemsNavBar,item.category_key, 'mobile')" [routerLink]="['/'+item.name.replaceAll(' ','-').toLowerCase(),'c'+item.category_key]">
                                        <span class="cate_name">{{item?.name}}</span>
                                    </a>
                                    <ul *ngIf="menuData[item.category_key]?.length > 0" class="dropdown-menu subcategory_menu_block subcategory_menu_list subSubCat" [ngClass]="{'active': activeDropdownIndex === i}" (mouseover)="applySubCategoryCustomClass(header?.menu_name)">
                                        <li *ngFor="let subMenu of menuData[item.category_key]" class="dropdown mega-menu">
                                            <a class="dropdown-toggle wrap_text" [title]="subMenu?.name" (click)="subCategoryRedirection($event,dropdownItemsNavBar,subMenu.category_key,'mobile')" [routerLink]="['/'+subMenu.name.replaceAll(' ','-').toLowerCase(),'c'+subMenu.category_key]">
                                                <span class="cate_name">{{subMenu?.name}}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>

        </div>
    </nav>
    <div class="clearfix"></div>
    <div class="mob_icons col-xs-12">
        <div class="myAcc">
            <div>
                <ul class="pr_list">
                    <li>
                        <div *ngIf="username ">
                            <a title="Welcome " class="custom-class_144 button_box " [routerLink]="[ '/edit-account'] " data-toggle="dropdown ">
                                <i class="fa fa-user "></i>
                            </a>

                            <ul *ngIf="showLogOutDesktop" class="dropdown-menu dropdown-menu-right dropdown-animation cart compare-top-margin " id="compare-mobmenu ">
                                <div class="pt-1 text-right compare-dropdown ">
                                    <a class="btn btn-group btn-gray btn-sm f-14 " style="margin-bottom: 12px; " [routerLink]="[ '/logout'] ">Logout</a>
                                </div>
                            </ul>
                        </div>
                        <div *ngIf="!username ">
                            <a class="custom-class_144 button_box " (click)="onClickMobileUser()">
                                <!--{{ headerdata?.secondRow[firstObj]?.menu_name }} -->
                                <i class="fa fa-user "></i>
                            </a>
                        </div>
                    </li>
                    <li class="phone">
                        <a href="tel:714-630-2764" title="TOLL FREE: 714-630-2764">
                            <i class="fa fa-phone acc-icons"></i>
                        </a>
                    </li>
                </ul>
            </div>
            <button (click)="openVechicleSearchPopup()" data-toggle="modal" data-target="#vehicleSearch" class="search_veh_mob">
                <a>Vehicle Search </a>
            </button>
            <div class="right_ico">
                <ul class="pr_list">
                    <li>
                        <div class="cart-icon">
                            <a [routerLink]="['/cart']">
                                <i class="fa fa-shopping-cart"></i>
                                <span class="badge badge-warning pull-right cart-badge" style="display: block ">
                                    <span id="header_items_in_cart_footer" class="header_items_in_cart">
                                        {{cartItems}}
                                    </span>
                                </span>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="dropdown-menu search_drp" id="dropdownMenuInputButtonMob1" [ngStyle]="{'display': displaySearchDivbyPartName(), 'width':'100%'}" #DivSearchByPartName>
            <input type="search" id="mobile_keyword_search1" class="form-c-search form-control" name="keyword" placeholder="Search By Part Name" autocomplete="off" [(ngModel)]="searchValueByPartName" (ngModelChange)="onInputvalueChangeByPartName()">
            <input type="hidden" name="part_name" value="1">
            <button type="submit" id="searchbtn">
                <i class="fa fa-search" id="icon-search"></i>
            </button>
            <div *ngIf="searchValueByPartName.length > 0" id="mobile_insert_search1" style="
            background: #fff;
            max-height: 505px;
            overflow: auto;
            box-shadow: 0 0 5px #363636;" #optionSearchbyPartNameMobile>

                <div *ngFor="let result of searchResultArrayByPartName ">
                    <!-- (click)="onClickSearchByPartNameResult(result.product_key,result.name)" -->
                    <a (click)="onClickSearchByPartNameResult(result.product_key,result.name)">
                        {{result.name}}
                    </a>
                </div>
            </div>
        </div>
        <div class="dropdown-menu search_drp" id="dropdownMenuInputButtonMob2" [ngStyle]="{'display': displaySearchDivbyPartHash(), 'width': '100%'}" #DivSearchByPartHash>
            <input type="search" id="mobile_keyword_search2" class="form-c-search form-control" name="keyword" placeholder="Search By Part #" [(ngModel)]="searchValueByPartHash" (ngModelChange)="onInputvalueChangeByPartHash()">
            <input type="hidden" name="part_name" value="0">
            <button type="submit" id="searchbtn">
                <i class="fa fa-search" id="icon-search"></i>
            </button>
            <div *ngIf="searchValueByPartHash.length > 0" id="mobile_insert_search2" style="
                background: #fff;
                max-height: 505px;
                overflow: auto;
                box-shadow: 0 0 5px #363636;
                " #optionSearchbyPartHashMobile>
                <div *ngFor="let result of searchResultArrayByPartHash">
                    <!-- (click)="onClickSearchByPartNameResult(result.product_key,result.name)" -->
                    <a (click)="onClickSearchByPartHashResult(result.product_key,result.product_variation_key,result.name,result.sku)">
                        {{result.sku}} - {{result.name}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<header class="header clearfix rocket_header d-none d-sm-block d-md-block d-lg-block">
    <div class="container">
        <div class="col-md-3 nopadding showOn768" style="display: none">
            <div class="header-left clearfix">
                <div id="logo" class="logo">
                    <a [routerLink]="['/']" class="rocket_logo_position">
                        <img id="logo_img" [src]="storeImagePath+headerdata?.websiteHeader[firstObj+2]?.desktop_logo" alt="mcleodracing" />
                    </a>
                </div>
            </div>
        </div>
        <div class="col-md-12 rocketCatMenuBlock nopadding">
            <div class="header-right clearfix">
                <div class="main-navigation animated with-dropdown-buttons">
                    <nav class="navbar navbar-default" role="navigation">
                        <ul class="nav navbar-nav header_desktop_menu">
                            <!-- <app-category-details [dataFromController1]="variableFromController1"></app-category-details> -->
                            <li *ngFor="let header of headerdata?.thirdRow; let i = index" (mouseover)="onMouseHoverDropdownListElement($event,dropdownItemsNavBar,header.menu_name)" (mouseleave)="onMouseLeaveDropdownListElement($event,dropdownItemsNavBar,header.menu_name)" class="dropdown mega-menu tabMenus">
                                <a class="dropdown-toggle leftbgimagenone" (click)="categoriesRedirection(header)" [innerHTML]="header?.menu_name == 'Search By Vehicle ' ? vehicleImage() : displayName(header?.menu_name)" (mouseover)="applyCustomVehicleClassMethod(header?.menu_name)"
                                    (mouseleave)="removeCustomVehicleClassMethod(header?.menu_name)" [ngClass]="{'customHoverClassVehicle': header?.menu_name=='Search By Vehicle ' ? applyCustomVehicleClass : false,'customHoverClass':header?.menu_name !='Search By Vehicle ' ? applyCustomClass : false}"
                                    #navBarElements>
                                </a>
                                <ul class="dropdown-menu subcategory_menu_block subcategory_menu_list" (mouseover)="applySubCategoryCustomClass(header?.menu_name)" #dropdownItemsNavBar>
                                    <li class="dropdown mega-menu" (mouseover)="onMouseHoverDropdownListElement($event,dropdownItemsNavBar,header.menu_name)" (mouseleave)="onMouseLeaveDropdownListElement($event,dropdownItemsNavBar,header.menu_name)" *ngFor="let menu of menuData[header.category_key]">
                                        <a class="dropdown-toggle wrap_text" [title]="menu?.name" (click)="subCategoryRedirection($event,dropdownItemsNavBar,menu.category_key)" [routerLink]="['/'+menu.name.replaceAll(' ','-').toLowerCase(),'c'+menu.category_key]">
                                            <span class="cate_name">{{menu?.name}}</span>
                                        </a>
                                        <ul (mouseover)="applySubCategoryCustomClass(header?.menu_name)" class="dropdown-menu subcategory_menu_block subcategory_menu_list subCat" #dropdownItemsNavBar>
                                            <li *ngFor="let item of menuData[menu.category_key]" (mouseover)="onMouseHoverDropdownListElement($event,dropdownItemsNavBar,header.menu_name)" (mouseleave)="onMouseLeaveDropdownListElement($event,dropdownItemsNavBar,header.menu_name)" class="dropdown mega-menu">
                                                <a class="dropdown-toggle wrap_text" [title]="item?.name" (click)="subCategoryRedirection($event,dropdownItemsNavBar,item.category_key)" [routerLink]="['/'+item.name.replaceAll(' ','-').toLowerCase(),'c'+item.category_key]">
                                                    <span class="cate_name">{{item?.name}}</span>
                                                </a>
                                                <ul class="dropdown-menu subcategory_menu_block subcategory_menu_list subSubCat" (mouseover)="applySubCategoryCustomClass(header?.menu_name)" #dropdownItemsNavBar>
                                                    <li class="dropdown mega-menu" *ngFor="let subMenu of menuData[item.category_key]">
                                                        <a class="dropdown-toggle wrap_text" [title]="subMenu?.name" (click)="subCategoryRedirection($event,dropdownItemsNavBar,subMenu.category_key)" [routerLink]="['/'+subMenu.name.replaceAll(' ','-').toLowerCase(),'c'+subMenu.category_key]">
                                                            <span class="cate_name">{{subMenu?.name}}</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</header>