import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { AppConstants } from 'src/app/app.constants';

import { environment } from 'src/environments/environment.prod';
import { Subscription } from 'rxjs';
import { SearchByVehicleService } from 'src/app/service/search-by-vehicle.service';
import { CommanpageService } from 'src/app/service/commanpages.service';
import { CategoryBreadcrumbComponent } from '../../category-breadcrumb/category-breadcrumb.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-sub-category',
    templateUrl: './sub-category.component.html',
    styleUrls: ['./sub-category.component.css'],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        CategoryBreadcrumbComponent,
    ],

})

export class SubCategoryComponent implements OnInit, OnChanges {
  firstObj: number = this.appConstants.firstObj;
  cdnImagePath: string = environment.cdnImagePath;
  vehicleName: string = '';
  product_key: any;
  categoryKeyChangeSub: Subscription;
  isVehicleSearch: boolean = false;
  allProducts: any[] = []
  promotionDescription: string;

  @Input() category_key: number = 0;
  @Input() subCategories: any[]  = [];
  @Input() subProducts: any[] = []
  @Input() catProductPromotion : any;
  @Input() universalProducts: any[] = [];
  @Input() name: string;
  @Input() limitSubCategories: number;
  @Input() limitSubProducts: number;
  @Input() hideProductsLoadMore: boolean;
  @Input() hideCategoriesLoadMore: boolean;
  @Output() refreshPageEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() subCategoriesLoadMore: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() subProductsLoadMore: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('loadMoreRef') loadMoreRef: ElementRef;

  constructor(
    private appConstants: AppConstants,
    private router: Router,
    private vehicleSearchService: SearchByVehicleService,
    private commonPageService: CommanpageService
  ) {}

  ngOnInit(): void {
    this.category_key = +localStorage.getItem('selectedcategorykey');
    this.isVehicleSearch = this.vehicleSearchService.isSearchVehicleExists();
    this.vehicleName = !this.isVehicleSearch ? '' : this.vehicleSearchService.getVechicleName();
  }

  ngAfterViewInit(): void {
    this.initIntersectionObserver();
  }

  ngOnChanges(changes: SimpleChanges): void {
   if (changes?.['subCategories'] || changes?.['subProducts']) {
    if (this.universalProducts.length > 0 && this.subProducts.length > 0) {
      this.combineAllProducts();
    }
    else if (this.universalProducts.length > 0) {
      this.allProducts = [...this.universalProducts];
    }
    else if(this.subProducts.length > 0){
      this.allProducts = [...this.subProducts]
    }
    else{
      this.allProducts = []
    }
   }
  }

  combineAllProducts(){
    this.allProducts = [...this.universalProducts , ...this.subProducts];
  }

  productRedirection(product: {product_key: number , name: string}) {
    localStorage.removeItem('selectedcategorykey');
    const productName = this.commonPageService.setUrlNameField(product.name);
    this.router.navigate(['/'+productName,'p'+product.product_key])
  }

  categoryRedirection(category_key: number,category_name: string){
    localStorage.setItem('selectedcategorykey',String(category_key));
    this.router.navigate(['/'+category_name.replaceAll(' ','-').toLowerCase(),'c'+category_key]);
  }

  onClearVehicleSearch(){
    this.vehicleSearchService.clearVehicleSearch();
    this.refreshPageEvent.emit(true);
  }

  onClickCategoryLoadMore(){
    this.subCategoriesLoadMore.emit(true);
  }

  onClickProductLoadMore(){
    this.subProductsLoadMore.emit(true);
  }

  initIntersectionObserver(): void {
    const options = {
      root: null,
      rootMargin: '200px', // trigger when the target is 200px 
      threshold: 1.0 // trigger when the target is fully in view
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.onClickCategoryLoadMore();
        }
      });
    }, options);
    observer.observe(this.loadMoreRef.nativeElement);
  }
}
